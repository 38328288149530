import React, {useState} from 'react';
import style from './open.module.css';
import NumPad from "./numPad/NumPad";


function OpenAnAccount({money = 0}) {
    // const telNum = useRef();
    const [telNum, setTelNum] = useState('');
    const [isSent, setIssent] = useState('no');
    const [errMessage, setErrMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('Ein Link wurde an Ihr Mobiltelefon gesendet. Verwenden Sie diesen Link, um ein Trading-Konto zu eröffnen und Ihren Kredit zu erhalten.')

    function show() {
        if (isSent === 'no') {
            return (<div className={style.main}>
                <h4>Deine Mobilnummer:</h4>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    const phoneNumberPattern = /^\d{9}$/;

                    if (!phoneNumberPattern.test(telNum)) {
                        setErrMessage('Bitte geben Sie Ihre Telefonnummer im korrekten Format ein: 791234567');
                        return;
                    }


                    const body = `+41${telNum}:${money}`;
                    console.log(JSON.stringify({
                        client: body
                    }))
                    setIssent('loading');
                    fetch('https://terminal.tradecenter24.com/winnerPhone', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            client: body
                        }),
                    })
                        .then(x => x.json())
                        // .catch(error => {
                        //     console.log('girdi')
                        //     setResponseMessage('Für dieses Quiz können Sie nur einen Preis erhalten.');
                        //     setIssent('response');
                        //
                        // })
                        .then(data => {
                            if (data?.code === 'ER_DUP_ENTRY') {
                                setResponseMessage(`<strong>Bereits registriert? Kein Problem!</strong> <br/>
<br/>
Auch wenn dieses spezielle Angebot exklusiv für Neukunden ist, haben wir großartige Vorteile und Promotionen für unsere geschätzten Bestandskunden:<br/>
<br/>
* <strong>IB-Werden</strong>: Nehmen Sie an exklusiven Programmen teil und profitieren Sie von besonderen Angeboten. <a href="https://tradecenter24.com/ib-werden/">[Mehr erfahren]</a><br/>
<br/>
* <strong>Online Wettbewerbe</strong>: Nutzen Sie Ihr bestehendes Handelskonto und tätigen Sie neue Einlagen, um an spannenden Wettbewerben teilzunehmen und fantastische Preise zu gewinnen. <a href="https://tradecenter24.com/wettbewerbe/">[Jetzt teilnehmen]</a><br/>
<br/>
* <strong>Bulls</strong>: Empfehlen Sie uns weiter! Für jeden Neukunden aus Ihrem Freundeskreis, den Sie uns vorstellen, erhalten Sie CHF 10. <a href="https://tradecenter24.com/bulls-terminal/">[Mitmachen]</a> <br/>
<br/>
Danke für Ihr Verständnis und Ihre Treue! Wir freuen uns darauf, Ihnen weiterhin herausragende Erlebnisse zu bieten.`);
                                setIssent('response');
                            }
                            console.log(data);
                            setIssent('response')
                        })

                }}>
                    <span style={{marginRight: '65px'}}>
                        <h4 style={{fontSize: '3rem'}}>+41</h4>
                    <input value={telNum} onChange={(e) => setTelNum(e.target.value)} type="tel"
                           placeholder={'79 123 45 67'}/>
                    </span>
                    <span>{errMessage}</span>
                    <NumPad setTelNum={setTelNum}/>
                    <button>ERÖFFNE KONTO</button>
                </form>
                <h4>um deinen Gewinn mitzunehmen</h4>
            </div>)
        } else if (isSent === 'loading') {
            return <div className={style.loader}></div>
        } else {
            return (
                <div className={style.main}>
                    <h4 dangerouslySetInnerHTML={{__html:responseMessage}}></h4>
                </div>
            )
        }
    }

    return show();
}

export default OpenAnAccount;