import React, {useEffect, useRef} from 'react';
import style from './win.module.css';
import OpenAnAccount from "../openAnAccount/OpenAnAccount";

function Win({num}) {

    function moneyCount() {
        if(num === 4){   
            return 20;
        }else if(num === 5){
            return 30;
        }else if(num === 6){
            return 50;
        }else if(num === 7){
            return 75;
        }else if(num ===8){
            return  100;
        }
    }
    const winningMusic = useRef(new Audio('/assets/sound/sound300.mp3'));
    useEffect(() => {
        winningMusic.current.play();
    }, []);
    return (
        <section className={style.main}>
            <img className={style.winner} src="/assets/img/bg-winner.png" alt="winner"/>
            <div className={style.center}>
            </div>
            <div className={style.realCenter}>
                {/*<img src="/assets/img/logo.png" alt="logo"/>*/}
                <h1>G E W I N N E R</h1>
                {/*<img src="/assets/img/money.png" alt="money"/>*/}
                <h2>CHF {moneyCount()}</h2>
                <h3>GUT GEMACHT! NIMM DEINEN GEWINN!</h3>
                <div className={style.open}>
                    <OpenAnAccount money={moneyCount()}/>
                </div>
            </div>

        </section>
    );
}

export default Win;