import React, {useState} from 'react';
import style from './lose.module.css';
import OpenAnAccount from "../openAnAccount/OpenAnAccount";
import WinScoreBoard from "../winScoreBoard/WinScoreBoard";
import Win from "../win/Win";

function Lose({reason = 'time', setGameState, num, setNum}) {

    const [showWin, setShowWin] = useState(false);
    const getHeading = () => {
        if (reason === "time") {
            if (num > 3){
                setShowWin(true);
                return ;
            }
            return "OOP! ZEIT ABGELAUFEN”";
        } else {
            console.log('num=', num);
            if (num <= 1) {
                return "FAST.. GIB NICHT AUF!";
            } else if (num === 2) {
                return "GEHE WEITER CHF 20 SIND GESICHERT!";
            } else if (num === 3) {
                return "FAST.. GIB NICHT AUF!";
            } else {
                setShowWin(true);
            }
        }
    }

    const losePage = () => {
        return showWin === false ? (<section className={style.main}>
            <div className={style.left}>
                <div>
                    {/*<WinScoreBoard score={num}/>*/}
                    <WinScoreBoard score={num}/>
                </div>
            </div>

            <div className={style.right}>
                <div>
                    <img src="/assets/img/logo.png" alt="logo"/>
                    <h1>{getHeading()}</h1>
                    <button onClick={() => {
                        setNum(0);
                        setGameState(3);
                    }}>NOCH EINMAL
                    </button>
                    {/*<div className={style.down}>*/}
                    {/*    <OpenAnAccount/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>) : <Win num={num}/>
    }

    return (<>{losePage()}</>);
}

export default Lose;