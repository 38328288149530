import React from 'react';

function Button({children, setTelNum}) {
    return (
        <div onClick={() => {
            if (!isNaN(children)) {
                setTelNum(prev => prev !== undefined && prev !== null ? String(prev) + children : String(children));
            } else if (children === '<') {
                setTelNum(prev => {
                    const str = String(prev);
                    return str.length > 0 ? str.slice(0, -1) : '';
                });
            }else if(children === 'X') {
                setTelNum('');
            }
        }}>{children}</div>
    );
}

export default Button;
