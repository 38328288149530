import React from 'react';
import style from './numPad.module.css';
import Button from "./Button";
function NumPad({setTelNum}) {
    return (
        <div className={style.main}>
            <Button setTelNum={setTelNum}>1</Button>
            <Button setTelNum={setTelNum}>2</Button>
            <Button setTelNum={setTelNum}>3</Button>
            <Button setTelNum={setTelNum}>4</Button>
            <Button setTelNum={setTelNum}>5</Button>
            <Button setTelNum={setTelNum}>6</Button>
            <Button setTelNum={setTelNum}>7</Button>
            <Button setTelNum={setTelNum}>8</Button>
            <Button setTelNum={setTelNum}>9</Button>
            <Button setTelNum={setTelNum}>{'<'}</Button>
            <Button setTelNum={setTelNum}>0</Button>
            <Button setTelNum={setTelNum}>X</Button>
        </div>
    );
}

export default NumPad;