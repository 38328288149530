import React from 'react';
import style from './winScoreBoard.module.css';
import WinLine from "./line/WinLine";

function WinScoreBoard({score}) {

    const line = Array.from({length: 8}, (_, index) => {
        if (index === score) {
            return (
                <WinLine key={index} color={'yellow'} index={index+1}/>
            )
        } else if (score> index && index === 3) {
            return (
                <WinLine key={index} color={'red'} index={index+1} />
            )
        }
        return (
            <WinLine key={index} index={index+1}/>
        )
    });
    return (
        <div className={style.main}>
            {line}
        </div>
    );
}

export default WinScoreBoard;