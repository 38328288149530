import React, {useEffect} from 'react';

import style from './welcome.module.css'

function Welcome({setGameState, setPlayFirstMusic}) {
    useEffect(() => {
        setGameState(prev=> prev+1)
    }, []);
    return (
        <section className={style.welcome}>
            <div className={style.logo}>
                <img src="assets/img/bulls.png" alt="logo"/>
                <p>TRADE CENTER AG</p>
            </div>

            <div className={style.main}>
                <img src="/assets/img/logo.png" alt="Logo"/>
                <button onClick={() => {
                    setGameState(prev => prev + 1);
                    setPlayFirstMusic(true);
                }}>FORTFAHEN</button>
            </div>
            <div className={style.end}>
                <h3>WIR FÖRDERN ANLAGEKULTUR</h3>
            </div>
        </section>
    );
}

export default Welcome;