import React from 'react';
import style from './central.module.css';
import Crump from "./crump/Crump";

function Central({score}) {

    const crumps = Array.from({length: 8}, (_, index) => {
        if (index === score) {
            return (
                <Crump key={index} color={'yellow'}/>
            )
        } else if (index < score) {
            return (
                <Crump key={index} color={'green'}/>
            )
        } else {
            return (
                <Crump key={index} color={'white'}/>
            )
        }
    });

    return (
        <div className={style.main}>
            <img src="/assets/img/logo.png" alt="logo"/>


            <div className={style.breadCruns}>
                {crumps}
            </div>
        </div>
    );
}

export default Central;