import React, {useEffect, useRef} from 'react';
import useCountdown from "../hooks/useCountdown";


function Time({callback,clockTickling }) {

    const time = useCountdown(20000, callback);

    useEffect(() => {

        // console.log('time', time)
        if((time/1000)===8){
            clockTickling.current.play();
            clockTickling.current.loop = true;
        }else if((time/1000) === 1){
            clockTickling.current.pause();
            clockTickling.current.loop = false;
        }

    }, [time]);
    return(
        <>
            {time/1000}
        </>
    )
}

export default Time;