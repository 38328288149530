import React from 'react';
import style from './line.module.css';

function Line({color, index}) {
    const money = () => {
        switch (index) {
            case 1:
                return 5;
            case 2:
                return 10;
            case 3:
                return 15;
            case 4:
                return 20;
            case 5:
                return 30;
            case 6:
                return 50;
            case 7:
                return 75;
            case 8:
                return 100;
        }
    }
    return (
        <div className={style.main}>

            <div className={[style.realLeft,
                (color === 'yellow' ? style.yellowLeft : ""),
                (color === 'red' ? style.redLeft : "")].join(" ")}>
                <h3 className={[(color === 'yellow' ? style.black  : "")].join(" ")}>{index}</h3>
            </div>


            <div className={[style.realRight,
                (color === 'yellow' ? style.yelllowRight : ""),
                (color === 'red' ? style.redRight : "")].join(" ")}>
                <h3 className={[(color === 'yellow' ? style.black : "")].join(" ")}>CHF {money()}</h3>
                {index === 4 ? (<div className={style.fire}>FIX</div>) : ""}
            </div>

        </div>
    );
}

export default Line;