import React from 'react';
import style from './thirdPage.module.css';

function ThirdPage({setGameState, setPlayFirstMusic}) {
    return (
        <section className={style.main}>
            <div className={style.up}>
                <h1>RECHTLICHER <span>HINWEIS</span></h1>
                <div className={style.realHeading}>
                    <hr style={{marginRight: '-10px'}}/>
                    <div className={style.yarpaq}>
                        <h2>Spielregeln <span>Quiz Game</span></h2>
                    </div>
                    <hr style={{marginLeft: '-10px', marginTop: '33px'}}/>
                </div>
            </div>

            <div className={style.down}>

                <div className={style.box}>
                    <h3>
                        Kostenlos:
                        <br/>
                        <br/>
                        <br/>
                    </h3>
                    <p>Das Spiel ist kostenlos und ohne Kaufzwang.</p>
                </div>
                <div className={style.box}>
                    <h3>Teilnahmebedingungen und Datenschutz:
                    </h3>
                    <p>Teilnahme ab 18 Jahren und mit Wohnsitz in der Schweiz. Datenschutz-informationen unter dem
                        angegebenen <a href={'https://tradecenter24.com/download/Teilnahmebedingungen_und_Spielregeln_Quiz-Spiel.pdf'}>Link</a>.
                        Datenschutz-informationen <a href="https://tradecenter24.com/download/Datenschutz.pdf">Link</a>.
                    </p>
                </div>
                <div className={style.box}>
                    <h3>Einmalige Teilnahme:
                        <br/>
                        <br/>
                    </h3>

                    <p>Spieler können das Spiel beliebig oft spielen, bis sie gewonnen haben. Nach einem Gewinn ist eine
                        erneute Teilnahme nicht gestattet.</p>
                </div>
                <div className={style.box}>
                    <h3>Spielverlauf:
                        <br/>
                        <br/>
                        <br/>
                    </h3>
                    <p>Das Spiel umfasst 8 Fragen mit jeweils 4 Antwortmöglich-keiten. Ein einmaliger Joker (50/50) kann
                        eingesetzt werden.</p>
                </div>
                <div className={style.box}>
                    <h3>Preisgeld:
                        <br/>
                        <br/>
                        <br/>
                    </h3>
                    <p>Frage 4: 20,<br/>
                        Frage 5: 30, <br/>
                        Frage 6: 50, <br/>
                        Frage 7: 75, <br/>
                        Frage 8: 100 als Vorschuss. Nur Gewinne über dem Vorschuss sind auszahlbar.</p>
                </div>
            </div>
            <button onClick={() => {
                setGameState(prev => prev + 1);
                setPlayFirstMusic(true);
            }}>WEITER</button>
        </section>
    );
}

export default ThirdPage;