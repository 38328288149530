import React from 'react';
import style from './crump.module.css';
function Crump({color}) {
    return (
        <div className={[style.crump,
            (color==='white'?style.white:""),
            (color==='yellow'?style.yellow:""),
            (color==='green'?style.green:"")].join(" ")}>
        </div>
    );
}

export default Crump;