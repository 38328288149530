import React, {useEffect, useRef, useState} from 'react';
import style from './game.module.css';
import Central from "../center/Central";
import ScoreBoard from "../scoreBoard/ScoreBoard";
import Quiz from "../quiz/Quiz";
import {advancedQuestions, basicQuestions, intermediateQuestions, lightQuestions} from "../../helperFuncs";
import Time from "../Time";


function Game({setGameState, num, setNum, setLoseReason}) {

    const [questions, setQuestions] = useState([
        ...lightQuestions(),
        ...basicQuestions(),
        ...intermediateQuestions(),
        ...advancedQuestions()
    ]);

    // let questions = {
    //     ...lightQuestions(),
    //     ...basicQuestions(),
    //     ...intermediateQuestions(),
    //     ...advancedQuestions()
    // }
    const [toShowTimeOrNor, setToShowTimeOrNot] = useState(true);
    const [isJokerClicked, setIsJokerClicked] = useState(false);
    const [isJokerMode, setIsJokerMode] = useState(false);
    const [isJokerVisible, setIsJokerVisible] = useState(false);

    console.log('questions:', questions);
    console.log('-------------Render-------------')
    useEffect(() => {
        let startSong = new Audio("/assets/sound/startSong.mp3");
        startSong.play();
        setQuestions( [
            ...lightQuestions(),
            ...basicQuestions(),
            ...intermediateQuestions(),
            ...advancedQuestions()]);
        setToShowTimeOrNot(true);
        setIsJokerClicked(false);
        setIsJokerMode(false);
    }, []);
    let clockTickling = useRef(new Audio("assets/sound/clockTickling.mp3"));
    useEffect(() => {
        if(!toShowTimeOrNor){
            clockTickling.current.pause();
            clockTickling.current.loop = false;
        }
    }, [toShowTimeOrNor]);

    return (
        <section className={style.main}>
            <div className={style.up}>
                <div className={style.timeAndJokerLeft}>
                    <div className={style.time}>
                        {toShowTimeOrNor && <Time callback={() => setGameState(prev => prev + 1)} clockTickling={clockTickling}/>}
                        <p>sec</p>
                    </div>

                    {/*<div className={style.joker}*/}
                    {/*     onClick={() => {*/}
                    {/*         if (!isJokerClicked) {*/}
                    {/*             setIsJokerMode(true);*/}
                    {/*             setIsJokerClicked(true);*/}
                    {/*         }*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <h3>50/50</h3>*/}
                    {/*    <img className={style.krasnaya} src="assets/img/bg-joker.png" alt="joker"/>*/}
                    {/*    <h5>JOKER</h5>*/}
                    {/*    <img className={style.flame} src="assets/img/burn.png" alt="burn"/>*/}
                    {/*    {isJokerClicked && <div className={style.used}></div>}*/}
                    {/*</div>*/}

                    <div className={style.tempJoker}
                         onClick={() => {
                             new Audio('/assets/sound/choice.mp3').play();
                             if (!isJokerClicked) {
                                 setIsJokerMode(true);
                                 setIsJokerClicked(true);
                             }
                         }}
                    >
                        {isJokerClicked === false ? <img src='/assets/img/joker.png' alt={'joker'}/> :
                            <img src='/assets/img/jokerUsed.png' alt={'joker'}/>}

                    </div>
                    {isJokerVisible&&<div className={style.curtains}></div>}
                </div>

                <div className={style.center}>
                    <Central score={num}/>
                </div>

                <div className={style.scoreBoard}>
                    <ScoreBoard score={num}/>
                </div>
                <div className={style.lines}>
                    <hr/>
                    <hr/>
                </div>
            </div>

            {/*-----------GameStartsHere-----------*/}
            <div className={style.game}>
                <Quiz
                    question={questions[num]}
                    setNum={setNum}
                    setGameState={setGameState}
                    setToShowTimeOrNot={setToShowTimeOrNot}
                    isJokerMode={isJokerMode}
                    setIsJokerMode={setIsJokerMode}
                    setLoseReason={setLoseReason}
                    num={num}
                    setIsJokerVisible={setIsJokerVisible}
                />
            </div>
        </section>
    );
}

export default Game;