// import light from "./db/light.json";
// import  basic from  "./db/basic.json";
// import  intermediate from  "./db/intermediate.json";
// import  advanced from  "./db/advanced.json";
//
// function randomIntFromInterval(min, max) { // min and max included
//     return Math.floor(Math.random() * (max - min + 1) + min);
// }
//
// export function getRandomNumExcept(except){
//     let randomNums = [];
//     while (randomNums.length < 2) {
//         let randomNum = randomIntFromInterval(1,4);
//         if(randomNum !== except && !randomNums.includes(randomNum)){
//             randomNums.push(randomNum);
//         }
//     }
//     return randomNums;
// }
//
// export const lightQuestions = () => {
//     const questions = [];
//     const randomNums = [];
//
//     for (let i = 0; i < 3; i++) {
//         let random = randomIntFromInterval(0, 137);
//         if (!randomNums.includes(random)) {
//             randomNums.push(random)
//         }
//     }
//
//     questions.push(light.questions[randomNums[0]]);
//     questions.push(light.questions[randomNums[1]]);
//     questions.push(light.questions[randomNums[2]]);
//
//     return questions;
// }
//
//
// export const basicQuestions = () => {
//     const questions = [];
//     const randomNums = [];
//
//     for (let i = 0; i < 2; i++) {
//         let random = randomIntFromInterval(0, 66);
//         if (!randomNums.includes(random)) {
//             randomNums.push(random)
//         }
//     }
//
//     questions.push(basic.questions[randomNums[0]]);
//     questions.push(basic.questions[randomNums[1]]);
//
//     return questions;
// }
//
// export const intermediateQuestions = ()=>{
//     const questions = [];
//     // const randomNums = [];
//
//     // for (let i = 0; i < 2; i++) {
//     //     let random = randomIntFromInterval(0, 19);
//     //     if(!randomNums.includes(random)){
//     //         randomNums.push(random)
//     //     }
//     // }
//     //
//     // questions.push(intermediate.questions[randomNums[0]]);
//     // questions.push(intermediate.questions[randomNums[1]]);
//
//     questions.push(intermediate.questions[randomIntFromInterval(0, 19)]);
//
//     return questions;
// }
//
// export const advancedQuestions = () => {
//     const questions = [];
//     const randomNums = [];
//
//     for (let i = 0; i < 2; i++) {
//         let random = randomIntFromInterval(0, 18);
//         if(!randomNums.includes(random)){
//             randomNums.push(random)
//         }
//     }
//
//     questions.push(advanced.questions[randomNums[0]]);
//     questions.push(advanced.questions[randomNums[1]]);
//
//     return questions;
// };
//

import light from "./db/light.json";
import basic from "./db/basic.json";
import intermediate from "./db/intermediate.json";
import advanced from "./db/advanced.json";

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// export function getRandomNumExcept(except) {
//     const possibleNums = [1, 2, 3, 4].filter(num => num !== except);
//     const randomNums = new Set();
//     while (randomNums.size < 2) {
//         const randomNum = possibleNums[randomIntFromInterval(0, possibleNums.length - 1)];
//         randomNums.add(randomNum);
//     }
//     return Array.from(randomNums);
// }

export function getRandomNumExcept(except) {
    const possibleNums = [1, 2, 3, 4].filter(num => num !== except);

    // Ensure possibleNums contains at least two elements
    if (possibleNums.length < 2) {
        throw new Error("Not enough possible numbers to choose from.");
    }

    const randomNums = new Set();
    while (randomNums.size < 2) {
        const randomNum = possibleNums[randomIntFromInterval(0, possibleNums.length - 1)];
        randomNums.add(randomNum);
    }
    return Array.from(randomNums);
}

function getRandomQuestions(db, range, count) {
    const questions = [];
    const randomNums = new Set();
    while (randomNums.size < count) {
        const random = randomIntFromInterval(0, range);
        randomNums.add(random);
    }
    randomNums.forEach(num => questions.push(db.questions[num]));
    return questions;
}

export const lightQuestions = () => getRandomQuestions(light, 137, 3);
export const basicQuestions = () => getRandomQuestions(basic, 66, 2);
export const intermediateQuestions = () => getRandomQuestions(intermediate, 19, 1);
export const advancedQuestions = () => getRandomQuestions(advanced, 18, 2);

