import React, {useState} from 'react';
import style from './answer.module.css';

function Answer({
                    answer,
                    isCorrect,
                    setNum,
                    setGameState,
                    setToShowTimeOrNot,
                    setIsJokerMode,
                    setLoseReason,
                    num,
                    setShowButtons,
                    setIsJokerVisible
                }) {
    const [showAnswer, setShowAnswer] = useState(false);
    const [showWrong, setShowWrong] = useState(false);

    console.log(answer, isCorrect)
    if (isCorrect) {
        return (
            <div className={[
                style.main,
                showAnswer === true ? style.greenImg : ""
            ].join(' ')}
                 onClick={() => {
                     console.log('ifeGirir')
                     setShowAnswer(true);
                     setShowButtons(true);
                     new Audio('/assets/sound/sound238.mp3').play();
                     setToShowTimeOrNot(false);
                     setIsJokerVisible(true);
                     setTimeout(() => {
                         setIsJokerVisible(false);
                         if (num === 7) {
                             setNum(prev => prev + 1);
                             setGameState(prev => prev + 2);
                             return;
                         } else {
                             setIsJokerMode(prev => {
                                 if (prev) {
                                     return false;
                                 }
                                 return false;
                             });
                             setShowButtons(false)
                             setShowAnswer(false);
                             setNum(prev => prev + 1);
                             setToShowTimeOrNot(true);
                         }
                     }, 3000)
                 }}>
                <h3>{answer}</h3>
            </div>
        );
    }
    return (
        <div className={[
            style.main,
            showWrong === true ? style.redImg : ""
        ].join(' ')} onClick={() => {
            console.log('returna girir');
            // setIsJokerVisible(true);
            setShowWrong(true);
            setShowButtons(true)
            new Audio('/assets/sound/sound238.mp3').play();
            setToShowTimeOrNot(false);
            setTimeout(() => {
                // setIsJokerVisible(false);
                setLoseReason('wrongAnswer');
                setGameState(prev => prev + 1);
                console.log('sehvin TimeOutuna  girdi')
            }, 3000);
        }}>
            <h3>{answer}</h3>
            {/*<h3>abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=[]{}|;12345</h3>*/}
        </div>
    );
}

export default Answer;





