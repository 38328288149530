import React, {useMemo, useState} from 'react';
import style from './quiz.module.css';
import {getRandomNumExcept} from "../../helperFuncs";
import Answer from "./answer/Answer";

function Quiz({
                  question,
                  setNum,
                  setGameState,
                  setToShowTimeOrNot,
                  isJokerMode,
                  setIsJokerMode,
                  setLoseReason,
                  num,
                  setIsJokerVisible
              }) {

    const [showButtons, setShowButtons] = useState(false);
    const randomNums = useMemo(() => getRandomNumExcept(Number(question.correctAnswer[Number(question.correctAnswer.length - 1)])), [question]);

    function checkName(question) {
        return (question.question.split('').length >= 100 ? {fontSize: "1rem"} : {})
    }

    // function configureAnswers(answerNum) {
    //     const randomNums = getRandomNumExcept(answerNum);
    //     const answers = []
    //     for (let i = 1; i <= 4; i++) {
    //         if (!isJokerMode) {
    //             let isCorr = false;
    //             if (i === answerNum) {
    //                 isCorr = true;
    //             }
    //             answers.push(
    //                 <Answer key={i} answer={question[`answer${i}`]} isCorrect={isCorr} setIsJokerMode={setIsJokerMode}
    //                         setToShowTimeOrNot={setToShowTimeOrNot}
    //                         setGameState={setGameState} setNum={setNum} setLoseReason={setLoseReason} num={num}
    //                         setShowButtons={setShowButtons} setIsJokerVisible={setIsJokerVisible}/>
    //             )
    //         } else {
    //             let isCorr = false;
    //             if (i === answerNum) {
    //                 isCorr = true;
    //             } else {
    //                 if (randomNums.includes(i)) {
    //                     continue;
    //                 }
    //             }
    //             answers.push(
    //                 <Answer key={i} answer={question[`answer${i}`]} isCorrect={isCorr}
    //                         setToShowTimeOrNot={setToShowTimeOrNot}
    //                         setGameState={setGameState} setNum={setNum} setIsJokerMode={setIsJokerMode}
    //                         setLoseReason={setLoseReason} num={num} setShowButtons={setShowButtons}
    //                         setIsJokerVisible={setIsJokerVisible}/>
    //             )
    //         }
    //     }
    //     return (<> {answers}</>);
    // }

    // console.log('answerIS',question.correctAnswer[question.correctAnswer.length - 1])
    console.log('Quiz RerenderOldu')

    function configureAnswers(answerNum) {
        const answers = []
        console.log('randomNums',randomNums)
        for (let i = 1; i <= 4; i++) {
            let isCorr = (i === answerNum);
            if (!isJokerMode || !randomNums.includes(i)) {
                answers.push(
                    <Answer key={i} answer={question[`answer${i}`]} isCorrect={isCorr} setIsJokerMode={setIsJokerMode}
                            setToShowTimeOrNot={setToShowTimeOrNot}
                            setGameState={setGameState} setNum={setNum} setLoseReason={setLoseReason} num={num}
                            setShowButtons={setShowButtons} setIsJokerVisible={setIsJokerVisible}/>
                );
            }
        }
        return (<> {answers}</>);
    }


    function getCorrectAnswer() {

        switch (question.correctAnswer) {
            case"answer1":
                return (configureAnswers(1));

            case "answer2":
                return (configureAnswers(2));

            case "answer3":
                return (configureAnswers(3));

            case "answer4":
                return (configureAnswers(4));
        }
    }


    return (
        <div className={style.main}>
            <div className={style.questionLine}>
                <div className={style.lineLeft}>
                    <hr/>
                </div>
                <div style={checkName(question)} className={style.question}>
                    <h3>{question.question}</h3>
                    {/*<h3>The quick brown fox jumps over the lazy dog near the riverbank, where the sun sets behind the hills.*/}
                    {/*    !</h3>*/}
                </div>
                <div className={style.lineRight}>
                    <hr/>
                </div>
            </div>
            <div className={style.boxing}>
                <div className={style.answers}>
                    {getCorrectAnswer()}
                </div>
                {showButtons && <div className={style.curtains}></div>}
            </div>
        </div>
    );
}

export default Quiz;




