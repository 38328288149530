import './App.css';
import {useEffect, useRef, useState} from "react";
import Game from "./components/game/Game";
import Welcome from "./components/welcome/Welcome";
import SecondPage from "./components/secondPage/SecondPage";
import Lose from "./components/lose/Lose";
import Win from "./components/win/Win";
import ThirdPage from "./components/thirdPage/ThirdPage";
import win from "./components/win/Win";

function App() {
    const [gameState, setGameState] = useState(0);
    const [num, setNum] = useState(0);
    const [loseReason, setLoseReason] = useState('time');
    const [playFirstMusic, setPlayFirstMusic] = useState(false);

    const firstSound = useRef(new Audio("/assets/sound/sound386.mp3"));
    useEffect(() => {
        console.log('girdi');
        if(playFirstMusic){
            firstSound.current.play()
            firstSound.current.loop = true;
        }else{
            firstSound.current.pause();
            firstSound.current.loop = false;
        }
    }, [playFirstMusic]);

    const game = [
        <Welcome setGameState={setGameState} setPlayFirstMusic={setPlayFirstMusic}/>,
        <ThirdPage setGameState={setGameState} setPlayFirstMusic={setPlayFirstMusic} />,
        <SecondPage setGameState={setGameState} setPlayFirstMusic={setPlayFirstMusic}/>,
        <Game setGameState={setGameState} num={num} setNum={setNum} setLoseReason={setLoseReason}/>,
        <Lose setGameState={setGameState} num={num!=0 && num<4?(num-1): num} setNum={setNum} reason={loseReason}/>,
        <Win num={num}/>

    ];
    return (
        game[gameState]
    );
}

export default App;
