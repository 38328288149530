import React, {useEffect} from 'react';
import style from './second.module.css';

function SecondPage({setGameState, setPlayFirstMusic}) {


    return (
        <section className={style.sec}>
            <h1>FOLGE <span>DEN SCHRITTEN</span> UND <span>VERDIENE MIT UNS!</span></h1>
            <div className={style.pizdes}>
                <div className={style.rowLeft}>
                    <hr/>
                    <div className={style.qush}>
                        <img src="/assets/img/arrow-left.png" alt="left"/>
                        <p>1</p>
                    </div>

                    {/*<div className={style.yarpaq}>*/}
                    {/*    <span className={style.border}>*/}
                    {/*        <h4>Win quiz-game and get trading capital</h4>*/}
                    {/*     </span>*/}
                    {/*</div>*/}
                    <div className={style.solYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Gewinne das Quizspiel und erhalte Handelskapital!</h4>
                    </div>
                </div>
                <div className={style.rowRight}>
                    {/*<div className={style.sagYarpaq}>*/}
                    {/*    <span className={style.borderRight}>*/}
                    {/*        <h4>Open an account</h4>*/}
                    {/*    </span>*/}
                    {/*</div>*/}

                    <div className={style.sagYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Eröffne ein S-Handelskonto und werde IB (Introducing Broker)!</h4>
                    </div>

                    <div className={style.qushRight}>
                        <img src="/assets/img/arrow-right.png" alt="right"/>
                        <p>2</p>
                    </div>
                    <hr/>
                </div>

                <div className={style.rowLeft}>
                    <hr/>
                    <div className={style.qush}>
                        <img src="/assets/img/arrow-left.png" alt="left"/>
                        <p>3</p>
                    </div>
                    {/*<div className={style.yarpaq}>*/}
                    {/*    <span className={style.border}>*/}
                    {/*    <h4>Start trading and become IB</h4>*/}
                    {/*    </span>*/}
                    {/*</div>*/}

                    <div className={style.solYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Rufe Schulungs-Hotline 044 503 53 53 an, starte das Trading!</h4>
                    </div>
                </div>

                <div className={style.rowRight}>
                    {/*<div className={style.sagYarpaq}>*/}
                    {/*    <span className={style.borderRight}>*/}
                    {/*        <h4>Participate in the trading contest </h4>*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div className={style.sagYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Nimm kostenlos an allen Wettbewerbsphasen teil! </h4>
                    </div>
                    <div className={style.qushRight}>
                        <img src="/assets/img/arrow-right.png" alt="right"/>
                        <p>4</p>
                    </div>
                    <hr/>
                </div>

                <div className={style.rowLeft}>
                    <hr/>
                    <div className={style.qush}>
                        <img src="/assets/img/arrow-left.png" alt="left"/>
                        <p>5</p>
                    </div>

                    {/*<div className={style.yarpaq}>*/}
                    {/*    <span className={style.border}>*/}
                    {/*        <h4>Deposit money and participate our teaching programs</h4>*/}
                    {/*     </span>*/}
                    {/*</div>*/}
                    <div className={style.solYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Wähle ein Programm (Silver, Gold oder Diamond) und lass dich
                            über die Hotline
                            schulen.</h4>
                    </div>
                </div>
                <div className={style.rowRight}>
                    {/*<div className={style.sagYarpaq}>*/}
                    {/*    <span className={style.borderRight}>*/}
                    {/*        <h4>Get your profit!</h4>*/}
                    {/*    </span>*/}
                    {/*</div>*/}

                    <div className={style.sagYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Empfehle Freunde als IB und erhalte CHF 10 pro neues Konto.</h4>
                    </div>
                    <div className={style.qushRight}>
                        <img src="/assets/img/arrow-right.png" alt="right"/>
                        <p>6</p>
                    </div>
                    <hr/>
                </div>

                <div className={style.rowLeft}>
                    <hr/>
                    <div className={style.qush}>
                        <img src="/assets/img/arrow-left.png" alt="left"/>
                        <p>7</p>
                    </div>
                    {/*<div className={style.yarpaq}>*/}
                    {/*    <span className={style.border}>*/}
                    {/*    <h4>Start trading and become IB</h4>*/}
                    {/*    </span>*/}
                    {/*</div>*/}

                    <div className={style.solYarpaqImg}>
                        <h4 style={{fontSize: '1.5rem'}}>Zahl Dir Deinen Gewinn aus!</h4>
                    </div>
                </div>
            </div>
            <button className={style.getStartedButton}
                    onClick={() =>{
                        setPlayFirstMusic(false);
                        setGameState(prev => prev + 1);
                    }}
            >LOS GEHT’S!
            </button>

        </section>
    );
}

export default SecondPage;